
<template>
	<div class="form-input-item item-border" v-if="inputType == 'input'">
		<div class="label-item">
			<span class="required">*</span>
			{{ label }}
		</div>

		<div class="input-box">
			<input type="text" :style="{width:inputWidth}" v-model="modelValue" />
		</div>
	</div>

	<div class="form-textarea-item item-border" v-else>
		<div class="label-item">
			<span class="required">*</span>
			{{ label }}
		</div>

		<div class="textarea-box">
			<textarea name="" id="" cols="15" rows="3"></textarea>
		</div>
	</div>
</template>

<script setup>
import { watch } from "vue";
const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	label: {
		type: String,
		default: "",
	},
	inputWidth: {
		type: String,
		default: "180px",
	},
	inputType: {
		type: String,
		default: "input",
	},
	modelValue: {
		type: String,
		default: "",
	},
});

// console.log(props);
watch(
	() => props.modelValue,
	(nVal) => {
		emits("update:modelValue", nVal);
	}
);
</script>

<style lang="scss" scoped>
.item-border {
	border: 1px solid #dddddd;
	border-radius: 10px;
}

.label-item {
	font-size: 16px;
	.required {
		font-size: 20px;
		color: red;
		margin-right: 7px;
	}
}

.form-input-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
	flex-wrap: nowrap;
	padding-left: 15px;
	.label-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		.required {
			font-size: 20px;
			color: red;
			margin-right: 7px;
		}
	}

	.input-box {
		padding: 15px;
		input {
			border: none;
			outline: none;
      font-size: 16px;
		}
	}
}

.form-textarea-item {
	width: 100%;
	padding: 15px;
  box-sizing: border-box;
	.label-item {
		.required {
			margin-right: 2px;
		}
	}

	.textarea-box {
		textarea {
			width: 100%;
			border: none;
			padding: 10px;
			outline: none;
			font-size: 16px;
			box-sizing: border-box;
			resize: none;
		}
	}
}
</style>