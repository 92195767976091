<template>
	<div class="grid-item-container" @click="navigateTo"></div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
	path: {
		type: String,
		deafult: "",
	},
});

// 点击跳转
const navigateTo = ()=>{
  router.push(props.path)
}

</script>

<style scoped lang="scss">
.grid-item-container {
  width:100%;
  height:100%;
}
</style>
