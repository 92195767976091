<template>
	<div id="floor-container">
		<img :src="bgImg" alt="" />

		<div class="open-box" @click="openUrl"></div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
	bgTheme: {
		type: String,
		deafult: "",
	},
});

// 图标
const bgImg = require(`@/assets/images/floor/${props.bgTheme}.png`);

const openUrl = () => {
	window.open("https://beian.miit.gov.cn");
};
</script>

<style scoped lang="scss">
#floor-container {
	position: relative;
	img {
		width: 1920px;
		object-fit: contain;
	}

	.open-box {
		width: 150px;
		position: absolute;
		height: 50px;
		bottom: 25px;
		right: 410px;
		z-index: 999999999;
		cursor: pointer;
	}
}
</style>
