

// 顶部选项卡
export const headerTabOpts = ()=>[{
  label:'网站首页',
  value:'/'
},{
  label:'智能硬件GUI',
  value:'/intelligentHardwareGUI'
},{
  label:'软件方案',
  type:'select',
  value:''
},{
  label:'智能软硬件方案',
  value:'/intelligentHardware'
},{
  label:'站点服务',
  value:'/siteServices'
},{
  label:'案例展示',
  value:'/casePresentation'
},{
  label:'外包服务',
  value:'/outsourcingServices'
},{
  label:'新闻资讯',
  value:'/newsInformation'
},{
  label:'关于我们',
  value:'/aboutUs'
}]

// 软件方案
export const appActionsOpts = ()=>[{
  label:'APP/H5',
  value:'/APPH5'
},{
  label:'软件定制',
  value:'/softwareCustomization'
},{
  label:'小程序',
  value:'/softwareProgram'
},{
  label:'公众号',
  value:'/softwarePublic'
},{
  label:'ERP',
  value:'/softwareERP'
},{
  label:'CRM',
  value:'/softwareCRM'
},{
  label:'OA',
  value:'/OAOffice'
}]