

import headerNavbar from './header-navbar.vue'
import formInput from './form-input.vue'
import floorBg from './floor-bg.vue'
import gridBoxItem from './grid-box-item.vue'

const components = {
  headerNavbar,
  formInput,
  floorBg,
  gridBoxItem
}

export default {

  install(Vue){

    for(let i in components){
      const ins = components[i]
      Vue.component(i,ins)
    }

  }

}