<template>
	<router-view />
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

// 跳转后返回顶部
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});
</script>

<style lang="scss">
@import "assets/css/global.scss";

* {
	padding: 0;
	margin: 0;
}
</style>
