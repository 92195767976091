import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 使用组件
import componentsIndex from '@/components/index'

import elementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(store).use(router).use(elementPlus).use(componentsIndex).mount('#app')
