import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/index.vue')
  },
  {
    path: '/systemFinancialInstitution',
    name: 'systemFinancialInstitution',
    meta: {
      title: '金融机构'
    },
    component: () => import('../views/system-financial-institution/index.vue')
  },
  {
    path: '/systemCrownSize',
    name: 'systemCrownSize',
    meta: {
      title: '冠字号'
    },
    component: () => import('../views/system-crown-size/index.vue')
  },
  {
    path: '/systemGetCustomers',
    name: 'systemGetCustomers',
    meta: {
      title: '获客'
    },
    component: () => import('../views/system-get-customers/index.vue')
  },
  {
    path: '/systemWebCustomized',
    name: 'systemWebCustomized',
    meta: {
      title: 'WEB定制'
    },
    component: () => import('../views/system-web-customized/index.vue')
  },
  {
    path: '/systemSmartBracelet',
    name: 'systemSmartBracelet',
    meta: {
      title: '智能手环'
    },
    component: () => import('../views/system-smart-bracelet/index.vue')
  },
  {
    path: '/systemAudioRental',
    name: 'systemAudioRental',
    meta: {
      title: '音响凭租'
    },
    component: () => import('../views/system-audio-rental/index.vue')
  },
  {
    path: '/systemPetroleumIndustry',
    name: 'systemPetroleumIndustry',
    meta: {
      title: '石油产业'
    },
    component: () => import('../views/system-petroleum-industry/index.vue')
  },
  {
    path: '/systemOralPatients',
    name: 'systemOralPatients',
    meta: {
      title: '口腔患者'
    },
    component: () => import('../views/system-oral-patients/index.vue')
  },
  {
    path: '/systemTraceability',
    name: 'systemTraceability',
    meta: {
      title: '溯源系统'
    },
    component: () => import('../views/system-traceability/index.vue')
  },
  {
    path: '/systemInspectionAlliance',
    name: 'systemInspectionAlliance',
    meta: {
      title: '检验联盟-健康管理'
    },
    component: () => import('../views/system-inspection-alliance/index.vue')
  },
  {
    path: '/APPH5',
    name: 'APPH5',
    meta: {
      title: 'APP/H5'
    },
    component: () => import('../views/APPH5/index.vue')
  },
  {
    path: '/ampere',
    name: 'ampere',
    meta: {
      title: 'ampere智能系统'
    },
    component: () => import('../views/ampere/index.vue')
  },
  {
    path: '/systemArGlasses',
    name: 'systemArGlasses',
    meta: {
      title: 'AR智能系统'
    },
    component: () => import('../views/system-ar-glasses/index.vue')
  },
  {
    path: '/systemBlueTechnology',
    name: 'systemBlueTechnology',
    meta: {
      title: '蓝网科技-掌中影'
    },
    component: () => import('../views/system-blue-technology/index.vue')
  },
  {
    path: '/systemNorthMonitoring',
    name: 'systemNorthMonitoring',
    meta: {
      title: '北线监控'
    },
    component: () => import('../views/system-north-monitoring/index.vue')
  },
  {
    path: '/systemHouseholdEnergy',
    name: 'systemHouseholdEnergy',
    meta: {
      title: '家庭储能'
    },
    component: () => import('../views/system-household-energy/index.vue')
  },
  {
    path: '/systemBtocShop',
    name: 'systemBtocShop',
    meta: {
      title: 'Btoc商城'
    },
    component: () => import('../views/system-btoc-shop/index.vue')
  },
  {
    path: '/systemHappyMeal',
    name: 'systemHappyMeal',
    meta: {
      title: '喜餐'
    },
    component: () => import('../views/system-happy-meal/index.vue')
  },
  {
    path: '/systemWeiranYunmu',
    name: 'systemWeiranYunmu',
    meta: {
      title: '蔚然云木'
    },
    component: () => import('../views/system-weiran-yunmu/index.vue')
  },
  {
    path: '/dongshenOperation',
    name: 'dongshenOperation',
    meta: {
      title: '东深运维'
    },
    component: () => import('../views/dongshen-operation/index.vue')
  },
  {
    path: '/systemAsmBudget',
    name: 'systemAsmBudget',
    meta: {
      title: 'ASM预算系统'
    },
    component: () => import('../views/system-asm-budget/index.vue')
  },
  {
    path: '/systemIntelligentPower',
    name: 'systemIntelligentPower',
    meta: {
      title: '智能配电'
    },
    component: () => import('../views/system-intelligent-power/index.vue')
  },
  {
    path: '/systemIntelligence',
    name: 'systemIntelligence',
    meta: {
      title: '智能储物柜'
    },
    component: () => import('../views/system-intelligence/index.vue')
  },
  {
    path: '/systemNewEnergy',
    name: 'systemNewEnergy',
    meta: {
      title: '新能源工界'
    },
    component: () => import('../views/system-new-energy/index.vue')
  },
  {
    path: '/systemSharedGym',
    name: 'systemSharedGym',
    meta: {
      title: '共享健身房'
    },
    component: () => import('../views/system-shared-gym/index.vue')
  },
  {
    path: '/systemSharedCharging',
    name: 'systemSharedCharging',
    meta: {
      title: '共享充电宝'
    },
    component: () => import('../views/system-shared-charging/index.vue')
  },
  {
    path: '/systemExpressLogistics',
    name: 'systemExpressLogistics',
    meta: {
      title: '快运物流'
    },
    component: () => import('../views/system-express-logistics/index.vue')
  },
  {
    path: '/systemNantianJustice',
    name: 'systemNantianJustice',
    meta: {
      title: '南天司法'
    },
    component: () => import('../views/system-nantian-justice/index.vue')
  },
  {
    path: '/systemShenzhenAccess',
    name: 'systemShenzhenAccess',
    meta: {
      title: '深圳公安门禁系统'
    },
    component: () => import('../views/system-shenzhen-access/index.vue')
  },
  {
    path: '/systemArbitrationCourt',
    name: 'systemArbitrationCourt',
    meta: {
      title: '国际仲裁院批量处理系统'
    },
    component: () => import('../views/system-arbitration-court/index.vue')
  },
  {
    path: '/systemInternetSharing',
    name: 'systemInternetSharing',
    meta: {
      title: '物联网分享'
    },
    component: () => import('../views/system-internet-sharing/index.vue')
  },
  {
    path: '/newsInformation',
    name: 'newsInformation',
    meta: {
      title: '新闻资讯'
    },
    component: () => import('../views/news-information/index.vue')
  },
  {
    path: '/systemYunchongIntelligence',
    name: 'systemYunchongIntelligence',
    meta: {
      title: '云宠智能'
    },
    component: () => import('../views/system-yunchong-intelligence/index.vue')
  },
  {
    path: '/intelligentHardware',
    name: 'intelligentHardware',
    meta: {
      title: '软件项目-智能软硬'
    },
    component: () => import('../views/intelligent-hardware/index.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: '关于我们'
    },
    component: () => import('../views/about-us/index.vue')
  },
  {
    path: '/outsourcingServices',
    name: 'outsourcingServices',
    meta: {
      title: '外包服务'
    },
    component: () => import('../views/outsourcing-services/index.vue')
  },
  {
    path: '/OAOffice',
    name: 'OAOffice',
    meta: {
      title: 'OA自动化办公'
    },
    component: () => import('../views/OA-office/index.vue')
  },
  {
    path: '/casePresentation',
    name: 'casePresentation',
    meta: {
      title: '案例展示'
    },
    component: () => import('../views/case-presentation/index.vue')
  },
  {
    path: '/softwareCRM',
    name: 'softwareCRM',
    meta: {
      title: 'CRM客户管理'
    },
    component: () => import('../views/software-CRM/index.vue')
  },
  {
    path: '/siteServices',
    name: 'siteServices',
    meta: {
      title: '站点服务'
    },
    component: () => import('../views/site-services/index.vue')
  },
  {
    path: '/softwarePublic',
    name: 'softwarePublic',
    meta: {
      title: '软件项目-公众号'
    },
    component: () => import('../views/software-public/index.vue')
  },
  {
    path: '/softwareProgram',
    name: 'softwareProgram',
    meta: {
      title: '软件项目-小程序'
    },
    component: () => import('../views/software-program/index.vue')
  },
  {
    path: '/softwareERP',
    name: 'softwareERP',
    meta: {
      title: '软件项目-ERP'
    },
    component: () => import('../views/software-ERP/index.vue')
  },
  {
    path: '/softwareCustomization',
    name: 'softwareCustomization',
    meta: {
      title: '软件项目-软件定制'
    },
    component: () => import('../views/software-customization/index.vue')
  },
  {
    path: '/intelligentHardwareGUI',
    name: 'intelligentHardwareGUI',
    meta: {
      title: '智能硬件-GUI'
    },
    component: () => import('../views/intelligent-hardware-GUI/index.vue')
  },
  {
    path: '/systemWardVideo',
    name: 'systemWardVideo',
    meta: {
      title: '病房视讯系统'
    },
    component: () => import('../views/system-ward-video/index.vue')
  },
  {
    path: '/systemCamelFilling',
    name: 'systemCamelFilling',
    meta: {
      title: '骆驼快充'
    },
    component: () => import('../views/system-camel-filling/index.vue')
  },
  {
    path: '/systemSteelStructure',
    name: 'systemSteelStructure',
    meta: {
      title: '中建钢构'
    },
    component: () => import('../views/system-steel-structure/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
