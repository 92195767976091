<template>
	<div id="header-container">
		<div class="container" :class="`${props.bgTheme}`">
			<!-- 显示的顶部背景图 -->
			<div class="show-bg" :style="{ 'background-image': `url(${bgImg})` }">
				<!-- <img class="bg-img" :src="bgImg" alt="" /> -->

				<!--  点击层 -->
				<div class="click-index" @click="showOverlay = true"></div>
			</div>
		</div>

		<!-- 遮罩层 -->

		<transition name="rotate">
			<div class="overlay" v-show="showOverlay">
				<!-- 顶部 -->
				<div class="container">
					<!-- 显示的顶部背景图 -->

					<div class="show-bg">
						<!--  点击层 -->
						<div class="click-index" @click="showOverlay = false"></div>
					</div>
				</div>

				<!-- 选项卡 -->
				<div class="tab-box">
					<div
						class="tab-item"
						v-for="(item, index) in tabOpts"
						:key="'key' + index"
						@click="tabClick(item)"
						:class="{ active: item.value === activePath(item) }"
					>
						<span class="sub-text">0{{ index + 1 }}</span>
						<span>{{ item.label }}</span>

						<!-- 选中图标 -->
						<img
							v-if="item.label == '软件方案'"
							class="change-icon"
							:src="downSelectIcon"
							alt=""
						/>

						<!-- 软件方案下拉框 -->
						<transition name="rotate">
							<div
								class="select-box"
								v-show="item.label == '软件方案' && showOptions"
							>
								<div class="tab-box">
									<div
										class="option"
										v-for="(item, index) in appActionsSelect"
										:key="'option' + index"
										@click.stop="downChose(item)"
									>
										{{ item.label }}
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";
import { headerTabOpts, appActionsOpts } from "@/utils/options.js";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

// 大选项卡
const tabOpts = headerTabOpts();
// 软件方案数据
const appActionsSelect = appActionsOpts();
// 软件方案路径
const appActionUrls = appActionsSelect.map((item) => item.value);

const props = defineProps({
	bgTheme: {
		type: String,
		deafult: "",
	},
});

// 选中大选项卡标签
const activePath = (item) => {
	const path = route.path;
	if (path == item.value) {
		return path;
	} else {
		const res = appActionUrls.find((d) => d === path);
		if (res) {
			// 软件方案value为 ''
			return "";
		}
	}

	return false;
};

// 图标
const bgImg = require(`@/assets/images/header-navbar/${props.bgTheme}.png`);

// 是否显示遮罩层
const showOverlay = ref(false);

// 是否打开下拉框
const showOptions = ref(false);
// 右侧选中图标
const downSelectIcon = computed(() => {
	const afterName = showOptions.value
		? "change-active.png"
		: "change-normal.png";
	return require(`@/assets/images/header-navbar/${afterName}`);
});

// 选中选项卡
const tabClick = (item) => {
	if (item.label == "软件方案") {
		showOptions.value = !showOptions.value;
	} else {
		showOptions.value = false;
		nextTick(() => {
			router.push(item.value);
		});
	}
};

// 选中下拉选项卡
const downChose = (item) => {
	showOverlay.value = false;
	nextTick(() => {
		router.push(item.value);
	});
};
</script>

<style scoped lang="scss">
#header-container {
	z-index: 10;
	&.sticky {
		position: sticky;
		top: 0px;
		left: 0px;
	}

	&.fixed {
		> .container {
			position: fixed;
			top: 0px;
			left: 0px;
		}
	}

	// 过渡动画
	.rotate-enter {
		opacity: 0;
		// transform: rotateX(0deg) !important;
	}
	.rotate-enter-to {
		opacity: 1;
		// transform: rotateX(0deg) !important;
	}
	.rotate-leave {
		opacity: 1;
	}
	.rotate-leave-to {
		opacity: 0;
		// transform: rotateX(90deg);
	}

	.rotate-enter-active,
	.rotate-leave-active {
		transition: 300ms;
	}

	// 顶部内容
	.container {
		width: 100vw;
		height: 70px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		z-index: 3;

		.show-bg {
			width: 100%;
			height: 100%;
			position: relative;

			.bg-img {
				height: 100%;
				object-fit: contain;
			}

			.click-index {
				width: 50px;
				height: 50px;
				position: relative;
				left: 1465px;
				top: 10px;
				cursor: pointer;
			}
		}
	}

	// 遮罩层
	.overlay {
		background-image: url(../assets/images/header-navbar/tab-bg.png);
		width: 100vw;
		position: fixed;
		top: 0px;
		left: 0;
		background-position: center;
		height: 100vh;
		z-index: 3;
		background-repeat: no-repeat;
		background-size: 1930px 1090px;

		.container {
			@extend .container;
			position: initial;

			.show-bg {
				background-image: url(../assets/images/header-navbar/header-tab-bg.png);
			}
		}

		> .tab-box {
			width: 300px;
			height: 550px;
			color: white;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;
			overflow-y: auto;
			margin: 50px 200px;

			&::-webkit-scrollbar {
				width: 6px;
				display: block;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: #004effff;
			}
			&::-webkit-scrollbar-track {
				border-radius: 10px;
			}

			.tab-item {
				text-align: left;
				width: 250px;
				font-size: 20px;
				line-height: 80px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				position: relative;

				&.active {
					color: #088bf4;
				}

				.change-icon {
					width: 25px;
					margin-left: 10px;
				}

				.sub-text {
					font-size: 15px;
				}

				.select-box {
					position: absolute;
					font-size: 16px;
					line-height: 40px;
					top: 70px;
					right: 70px;
					width: 180px;
					text-align: center;
					background-color: #fffffff0;
					border-radius: 10px;
					color: black;
					z-index: 4;

					> .tab-box {
						position: relative;

						&::before {
							content: " ";
							border: 5px solid;
							position: absolute;
							width: 1px;
							height: 1px;
							border-color: #f1f2f3 #f1f2f3 transparent transparent;
							top: -5px;
							transform: rotate(315deg);
							left: 35%;
						}

						.option {
							text-align: left;
							padding: 0px 55px;

							&:nth-child(1) {
								border-radius: 10px 10px 0 0;
							}
							&:hover {
								opacity: 0.6;
							}
							&:active {
								background-color: #005eeb;
								color: white;
							}
						}
					}
				}
			}
		}
	}
}
</style>
